




import { Component, Vue } from 'vue-property-decorator'
import { Game, Logs } from '@/api'
import { GameModule } from '@/store/modules'

@Component({
  components: {
    LogsTable: () => import('./LogsTable.vue')
  }
})
export default class UserFortuneWheelLogs extends Vue {
  @GameModule.State characters
  data = []

  async mounted() {
    const { result } = await Logs.getFortuneWheelLogs()
    const itemData = await Game.getItemsData(result.map(e => e.itemVnum))

    this.data = result
      .map(e => {
        return {
          spinId: e.id,
          character: this.characters.find(char => char.id === e.characterId).name,
          spinResult: itemData.find(item => item.vNum === e.itemVnum)?.name,
          date: new Date(e.date).toUTCString(),
        }
      })
      .reverse()
  }
}
